import * as React from 'react'
import Layout from '../../components/layout'
import { Titulo } from '../../components/titulo'
import { Container, Row, Col, Card } from 'react-bootstrap'
import {v4 as uuid} from 'uuid';
import presidenta from '../../images/centro-padres/presidenta.jpg'
import secretaria from '../../images/centro-padres/secretaria.jpg'
import tesorera from '../../images/centro-padres/tesorera.jpg'

export default function centropadres () {
    const data = [
        {id: uuid(), nombre: "Nataly Carrasco Hidalgo.", cargo: 'Presidenta', foto: presidenta},
        {id: uuid(), nombre: "Mónica Chávez Castillo.", cargo: 'Secretaria', foto: secretaria},
        {id: uuid(), nombre: "Lally Contreras", cargo: 'Tesorera', foto: tesorera},
    ]

    return (
        <Layout>
            <Titulo title="Centro de padres"  />
            <Container>
                <Row className=" justify-content-center">
                    { data && data.map( e => (
                        <Col md={4} lg={3} key={e.id} className="mt-2">
                            <Card className="bg-dark text-center">
                                <Card.Body>
                                    <img 
                                        src={e.foto} 
                                        alt="" 
                                        className="img-fluid img-rounded"
                                        height={100}
                                    /> 
                                    <h4>{ e.nombre }</h4>
                                    <h6>{ e.cargo }</h6>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Layout>
    )
}